import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

export class Collection extends Component {
    render() {
        return (
            <Fragment>
                <Container className="text-center" fluid={true}>
                    <div className="section-title text-center mb-55"><h2> PRODUCT COLLECTION</h2>
                        <p>Some Of Our Exclusive Collection, You May Like</p>
                    </div>

                    <Row>
                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/kf1fo280hlty2aw-0/t-shirt/w/s/e/-original-imafdfvvr8hqdu65.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Striped Men Hooded Neck Red</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>


                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/keykscw0-0/t-shirt/l/d/q/3xl-bmrgyrnful-z12-blive-original-imafvgzkyjghf7ba.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Striped Men Round Neck Maroon, Grey</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>



                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/jt4olu80/t-shirt/v/7/v/xl-t-shirt-0068-eg-original-imafejrfpzjkxvkq.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Color Block Men Round Neck Grey</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>



                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/kljrvrk0/t-shirt/q/r/0/l-trdhdful-d32-tripr-original-imagynnpg2fh62ht.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>


                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/kfmv9u80/t-shirt/t/f/p/l-bmrnvhenful-z14-blive-original-imafwfqkyfr3zxdr.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>



                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/keykscw0-0/t-shirt/d/2/e/l-bnvgyrnful-z12-blive-original-imafvgzk3mh2vpmt.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>



                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/kdnf98w0hlty2aw-0/t-shirt/r/5/v/l-tgy-rbvnd01ganesh-tripr-original-imafuzrq6hpafsmb.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>



                        <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="image-box card w-100">
                                <img className="center w-75" src="https://rukminim1.flixcart.com/image/800/960/jwxuvm80/t-shirt/c/n/g/s-1jgrfdotstpwh-nvy-jugular-original-imafhhajsn6yzg2a.jpeg?q=50" />
                                <Card.Body>
                                    <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
                                    <p className="product-price-on-card">Price : $120</p>

                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default Collection