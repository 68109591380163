import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

export class FeaturedProduct extends Component {
    render() {
        return (
            <Fragment>
                <Container className='text-center' fluid={true}>
                    <div className="section-title text-center mb-55">
                        <h2>Featured Product </h2>
                        <p>Some of Exclusive collection, You may Like</p>
                    </div>
                    <Row>
                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/r/s/r/-original-imagpfbtmrr7spgc.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>vivo T2 5G (Nitro Blaze, 128 GB)  (8 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 20900</p>

                                </Card.Body>
                            </Card>

                        </Col>

                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/a/l/m/-original-imagp55fu6uq2jch.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>realme C55 (Rainy Night, 64 GB)  (4 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 10999</p>

                                </Card.Body>
                            </Card>

                        </Col>

                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/r/s/r/-original-imagpfbtmrr7spgc.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>vivo T2 5G (Nitro Blaze, 128 GB)  (8 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 20900</p>

                                </Card.Body>
                            </Card>

                        </Col>

                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/l4ln8nk0/mobile/f/6/a/-original-imagfggrbywzk8r6.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>realme C30 (Denim Black, 32 GB)  (2 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 5999</p>

                                </Card.Body>
                            </Card>

                        </Col>
                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/l4ln8nk0/mobile/p/h/p/-original-imagfggryyzyvhgw.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>vivo T2 5G (Nitro Blaze, 128 GB)  (8 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 6499</p>

                                </Card.Body>
                            </Card>

                        </Col>
                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/w/o/s/-original-imaghuf9mryjhf3m.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>realme C33 2023 (Sandy Gold, 128 GB)  (4 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 10499</p>

                                </Card.Body>
                            </Card>

                        </Col>
                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/r/s/r/-original-imagpfbtmrr7spgc.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>vivo T2 5G (Nitro Blaze, 128 GB)  (8 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 20900</p>

                                </Card.Body>
                            </Card>

                        </Col>
                        <Col className='p-1' xl={2} key={1} lg={2} md={2} sm={4} xs={6}>

                            <Card className="image-box card">
                                <img className='center' src="https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/8/d/b/-original-imagp9s9wfzp8ghf.jpeg?q=70" />
                                <Card.Body>
                                    <p className='product-name-on-card'>realme C55 (Rainforest, 128 GB)  (8 GB RAM)</p>
                                    <p className='product-price-on-card'> Rs 13999</p>

                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default FeaturedProduct
