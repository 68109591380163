import React, { Component, Fragment } from 'react';
//import { Button } from 'react-bootstrap';
//npm install react-router-dom@5.2.0 --save
// npm i --save-dev @types/react-router-dom
//npm install react-router@5.2.0 --save

// to uninstall react latest
//npm uninstall react react-dom

// to install react @17.0.2 react-dom@17.0.2
// npm install react@17.0.2 react-dom@17.0.2

// npm install --save react-router-transition

//npm install animate.css --save


import { BrowserRouter } from 'react-router-dom';
import AppRoute from './route/AppRoute';

export class App extends Component {
  render() {
    return (
      
      <Fragment>
        <BrowserRouter>
          <AppRoute />

        </BrowserRouter>
      </Fragment>

    )
  }
}

export default App