import React, { Component, Fragment } from 'react'
import menulogo from '../../assets/images/homelogo.png';
export class MegaMenu extends Component {

  constructor()
  {
    super();
    this.MegaMenu=this.MegaMenu.bind(this);
  }

  componentDidMount()
  {
    this.MegaMenu();
  }

  MegaMenu()
  {
    var acc=document.getElementsByClassName("accordion");
    var accNum=acc.length;
    //alert(accNum)
    var i;
    for(i=0;i<accNum;i++)
    {
       acc[i].addEventListener("click",function(){
        this.classList.toggle("active"); // active the selected items 
        var panel=this.nextElementSibling; // next element
        // selected for left side menu items
        if(panel.style.maxHeight)
        {
          panel.style.maxHeight=null;
        }
        else
        {
          panel.style.maxHeight=panel.scrollHeight+"px";
        }
        // selected for left side menu items
       })
    }


  }
  render() {
    return (
      <div className='accordionMenuDiv'>
        <div className='accordionMenuDivInside'>
          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>

          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>

          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>
          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>
          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>
          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>
          <button className='accordion'>
            <img className='accordionMenuIcon' src={menulogo} />
            Men's Cloths
          </button>
          <div className='panel'>
            <ul>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 1</li>
              <li><a href='#' className='accordionItem'></a>Mens T-Shirt 2</li>

            </ul>
          </div>

        </div>
      </div>
    )
  }
}

export default MegaMenu